import { Box, Typography, Link } from '@mui/joy';
import { lighten } from 'polished';

interface OrderSummarySectionLinkProps {
    linkActive: boolean;
    linkText: string;
    organizerColor: string;
    onClickCalled: () => void;
}

export default function OrderSummarySectionLink({
    linkActive,
    linkText,
    onClickCalled,
    organizerColor,
}: OrderSummarySectionLinkProps) {
    return (
        <Box
            sx={{
                flex: 1,
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                padding: 1,
                paddingLeft: 2,
                borderColor: linkActive ? organizerColor : (theme) => theme.palette.divider,
                backgroundColor: (theme) =>
                    linkActive
                        ? lighten(0.5, organizerColor as string)
                        : theme.palette.background.surface,
                color: organizerColor,
                borderWidth: linkActive ? '2px' : '1px',
            }}
        >
            <Link onClick={onClickCalled} sx={{ width: '100%', textDecoration: 'none!important' }}>
                <Typography
                    level="body-lg"
                    sx={{
                        color: (theme) =>
                            linkActive ? organizerColor : theme.palette.neutral.plainColor,
                    }}
                >
                    {linkText}
                </Typography>
            </Link>
        </Box>
    );
}
