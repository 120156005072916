import { AddonCategoryBox } from '../orderSummaryPageJoy.styled';
import ProductDescription from '../ticketCategoryDescriptionJoy.component';
import { QuantityControl } from './quantityControl.component';
import { memo, useState } from 'react';
import { Box, Chip, Link, Stack, Typography } from '@mui/joy';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ImageModalJoy from '../../common/ImageModalJoy.component';
import { StarsRounded } from '@mui/icons-material';
import { BasicAddonInfo } from '../../../utils/addon/useBaseAddonCache';
import { CustomAddonChip } from '../orderSummaryPageJoy.styled';

export const AddonCard = memo(
    ({ item, organizerPrimaryColor }: { item: BasicAddonInfo; organizerPrimaryColor: string }) => {
        const [imageModalShowing, setImageModalShowing] = useState(false);

        return (
            <>
                {item?.hasSingleVariation ? (
                    <SingleVariationCard
                        key={item.category.name}
                        item={item}
                        organizerPrimaryColor={organizerPrimaryColor}
                        setImageModalShowing={setImageModalShowing}
                    />
                ) : (
                    <MultipleVariationCard
                        key={item.category.name}
                        item={item}
                        organizerPrimaryColor={organizerPrimaryColor}
                        setImageModalShowing={setImageModalShowing}
                    />
                )}

                {item.category.imageKey && (
                    <ImageModalJoy
                        closeModal={() => setImageModalShowing(false)}
                        imageState={imageModalShowing}
                        name={item.category.name}
                        imageKey={item.category.imageKey}
                    ></ImageModalJoy>
                )}
            </>
        );
    }
);

const SingleVariationCard = memo(
    ({
        item,
        organizerPrimaryColor,
        setImageModalShowing,
    }: {
        item: BasicAddonInfo;
        organizerPrimaryColor: string;
        setImageModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
    }) => {
        const variation = item.category.variations?.[0];
        if (!variation) return null;

        const ticketPairingRequired = Boolean(
            item?.category?.unlockWith &&
                typeof item.category.unlockWith === 'object' &&
                Object.keys(item.category.unlockWith).length > 0
        );

        return (
            <AddonCategoryBox id={`addon-card-${item.category.categoryId}`}>
                {ticketPairingRequired && (
                    <CustomAddonChip setcolor={organizerPrimaryColor}>
                        Only Available to Purchase with Select Ticket Types
                    </CustomAddonChip>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: 2,
                        padding: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 2,
                        }}
                    >
                        {item?.category.imageKey ? (
                            <img
                                onClick={() => setImageModalShowing(true)}
                                src={`https://media.admitone.com/addons/${item.category.imageKey}?width=64&height=64&fit=fill&fill=blur`}
                                alt={item.category.name}
                                style={{
                                    borderRadius: 8,
                                    cursor: 'pointer',
                                }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    width: 64,
                                    height: 64,
                                    background: organizerPrimaryColor,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 8,
                                }}
                            >
                                <StarsRounded sx={{ width: 60, height: 60, color: 'white' }} />
                            </Box>
                        )}
                        <Stack sx={{ gap: 0.5 }}>
                            <Typography>{item.category.name}</Typography>
                            {item.category.hashedUnlockCode &&
                                item.category.hashedUnlockCode.length > 0 && (
                                    <Typography
                                        level="body-xs"
                                        textColor={'text.tertiary'}
                                        sx={{
                                            fontWeight: 500,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LockOpenIcon fontSize={'inherit'} sx={{ mr: '5px' }} />{' '}
                                        <span>Unlocked Add-on</span>
                                    </Typography>
                                )}
                            <Typography sx={{ fontWeight: 600 }}>
                                {variation.priceRange.lowestPrice}
                            </Typography>
                            <ProductDescription
                                description={item.category.description as string}
                                organizerPrimaryColor={organizerPrimaryColor}
                            />
                        </Stack>
                    </Box>
                    <Stack>
                        {item.isAddonSoldOut ? (
                            <Chip>Sold Out</Chip>
                        ) : (
                            <>
                                <QuantityControl
                                    variationId={variation.variationId}
                                    setColor={organizerPrimaryColor}
                                    increment={variation.handlers.increment}
                                    decrement={variation.handlers.decrement}
                                    quantity={variation.currentCartQuantity}
                                    disabled={{
                                        minus: variation.buttonStates.isDisabledMinus,
                                        plus: variation.buttonStates.isDisabledPlus,
                                    }}
                                />
                                {item.totalAddonsCount >= 20 && (
                                    <Typography
                                        sx={{ color: 'red', alignSelf: 'center', fontSize: 12 }}
                                    >
                                        Max 20 per order
                                    </Typography>
                                )}
                            </>
                        )}
                    </Stack>
                </Box>
            </AddonCategoryBox>
        );
    }
);

const MultipleVariationCard = memo(
    ({
        item,
        organizerPrimaryColor,
        setImageModalShowing,
    }: {
        item: BasicAddonInfo;
        organizerPrimaryColor: string;
        setImageModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
    }) => {
        const [showDescription, setShowDescription] = useState(false);

        const ticketPairingRequired = Boolean(
            item?.category?.unlockWith &&
                typeof item.category.unlockWith === 'object' &&
                Object.keys(item.category.unlockWith).length > 0
        );

        return (
            <AddonCategoryBox id={`addon-card-${item.category.categoryId}`}>
                {ticketPairingRequired && (
                    <CustomAddonChip setcolor={organizerPrimaryColor}>
                        Only Available to Purchase with Select Ticket Types
                    </CustomAddonChip>
                )}
                <Stack
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 2,
                        padding: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: 2,
                        }}
                    >
                        {item?.category.imageKey ? (
                            <img
                                onClick={() => setImageModalShowing(true)}
                                src={`https://media.admitone.com/addons/${item.category.imageKey}?width=64&height=64&fit=fill&fill=blur`}
                                alt={item.category.name}
                                style={{
                                    borderRadius: 8,
                                    cursor: 'pointer',
                                }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    width: 64,
                                    height: 64,
                                    background: organizerPrimaryColor,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 8,
                                }}
                            >
                                <StarsRounded sx={{ width: 60, height: 60, color: 'white' }} />
                            </Box>
                        )}
                        <Stack sx={{ gap: 0.5 }}>
                            <Typography>{item.category.name}</Typography>
                            {item.category.hashedUnlockCode &&
                                item.category.hashedUnlockCode.length > 0 && (
                                    <Typography
                                        level="body-xs"
                                        textColor={'text.tertiary'}
                                        sx={{
                                            fontWeight: 500,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <LockOpenIcon fontSize={'inherit'} sx={{ mr: '5px' }} />{' '}
                                        <span>Unlocked Add-on</span>
                                    </Typography>
                                )}
                            <Typography sx={{ fontWeight: 600 }}>
                                {item.category.variations[0].priceRange.lowestPrice}
                            </Typography>
                            <ProductDescription
                                description={item.category.description as string}
                                organizerPrimaryColor={organizerPrimaryColor}
                            />
                        </Stack>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {item.category.variations.map((variation) => {
                            return (
                                <AddonCategoryBox>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-between',
                                            gap: 2,
                                            padding: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                gap: 2,
                                            }}
                                        >
                                            <Stack sx={{ gap: 0.5 }}>
                                                <Typography>{variation.name}</Typography>
                                                <Typography sx={{ fontWeight: 600 }}>
                                                    {variation.priceDifference}
                                                </Typography>
                                                {variation?.description && (
                                                    <Box>
                                                        {showDescription && (
                                                            <Typography>
                                                                {variation.description}
                                                            </Typography>
                                                        )}
                                                        <Link
                                                            onClick={() =>
                                                                setShowDescription(
                                                                    (state) => !state
                                                                )
                                                            }
                                                            underline="none"
                                                        >
                                                            <Typography
                                                                level="body-xs"
                                                                sx={{
                                                                    color: organizerPrimaryColor,
                                                                }}
                                                            >
                                                                {showDescription
                                                                    ? 'See less'
                                                                    : 'See more'}
                                                            </Typography>
                                                        </Link>
                                                    </Box>
                                                )}
                                            </Stack>
                                        </Box>
                                        <Stack>
                                            {item.isAddonSoldOut ? (
                                                <Chip>Sold Out</Chip>
                                            ) : (
                                                <>
                                                    <QuantityControl
                                                        variationId={variation.variationId}
                                                        setColor={organizerPrimaryColor}
                                                        increment={variation.handlers.increment}
                                                        decrement={variation.handlers.decrement}
                                                        quantity={variation.currentCartQuantity}
                                                        disabled={{
                                                            minus: variation.buttonStates
                                                                .isDisabledMinus,
                                                            plus: variation.buttonStates
                                                                .isDisabledPlus,
                                                        }}
                                                    />
                                                    {item.totalAddonsCount >= 20 && (
                                                        <Typography
                                                            sx={{
                                                                color: 'red',
                                                                alignSelf: 'center',
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            Max 20 per order
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                        </Stack>
                                    </Box>
                                </AddonCategoryBox>
                            );
                        })}
                    </Box>
                </Stack>
            </AddonCategoryBox>
        );
    }
);
