import { useMemo } from 'react';
import { AddonCategoryFragment, AddonVariationFragment } from '../../graphql/graphql';
import { A1CheckoutCartObject } from '../../types/common';
import { FeeID } from '../calculations';
import { formatPrice } from '../formatter';

export interface PriceRange {
    lowestPrice: string | number;
    highestPrice: string | number;
}

export interface ButtonStates {
    isDisabledPlus: boolean;
    isDisabledMinus: boolean;
}

interface HandlerParams {
    variation: {
        variationId: string;
        quantityLimit: number;
    };
    category: AddonCategoryFragment;
    inventory: number;
    currentCartQuantity: number;
    totalAddonsCount: number;
    totalTicketsCount: number;
    setAddonQuantity: (categoryId: string, variationId: string, quantity: number) => void;
}

interface QuantityHandlers {
    increment: () => void;
    decrement: () => void;
}

function useAddonsCount(cart: A1CheckoutCartObject) {
    return useMemo(
        () =>
            Object.values(cart.addons).reduce(
                (sum: number, value: number) => sum + (value || 0),
                0
            ),
        [cart.addons]
    );
}

function useTicketsCount(cart: A1CheckoutCartObject) {
    return useMemo(
        () =>
            Object.values(cart.quantities).reduce(
                (sum: number, value: number) => sum + (value || 0),
                0
            ),
        [cart.quantities]
    );
}

function createQuantityHandlers({
    variation,
    category,
    inventory,
    currentCartQuantity,
    totalAddonsCount,
    totalTicketsCount,
    setAddonQuantity,
}: HandlerParams): QuantityHandlers & ButtonStates {
    const isQuantityMinStrict = category.isQuantityMinStrict ?? false;
    const CART_LIMIT = 20;
    const minAddonQuantity = category.quantityMin ?? 1;
    const hasInsufficientInventory = inventory < minAddonQuantity;
    const maxAddonQuantity = category.quantityMax ?? 1;

    let minAdd = 1;
    if (hasInsufficientInventory || currentCartQuantity >= minAddonQuantity) {
        minAdd = 1;
    } else if (isQuantityMinStrict) {
        // Strict case: enforce minimum quantity, but don't exceed max
        minAdd = Math.min(minAddonQuantity, maxAddonQuantity);
    } else {
        minAdd = Math.min(minAddonQuantity, maxAddonQuantity);
    }

    const hasValidVariation = !!variation.variationId;

    const validateIncrement = (proposedAdd: number): boolean => {
        if (!variation.variationId || totalTicketsCount === 0) {
            return false;
        }

        // Check if we can add the proposed quantity
        const wouldExceedLimits =
            totalAddonsCount + proposedAdd > CART_LIMIT ||
            currentCartQuantity + proposedAdd > inventory ||
            currentCartQuantity + proposedAdd > variation.quantityLimit ||
            currentCartQuantity + proposedAdd > maxAddonQuantity;

        if (currentCartQuantity >= minAddonQuantity) {
            // After reaching minimum, allow single units if there's inventory
            return !wouldExceedLimits && inventory >= 1;
        } else if (isQuantityMinStrict) {
            // For strict minimum, must have enough for minimum quantity
            return !wouldExceedLimits && inventory >= minAddonQuantity;
        } else {
            // For non-strict, allow any quantity if there's inventory
            return !wouldExceedLimits && inventory >= 1;
        }
    };

    const increment = () => {
        if (!validateIncrement(minAdd)) {
            if (!hasInsufficientInventory && !isQuantityMinStrict) return;

            // Try single unit
            if (!validateIncrement(1)) return;
            setAddonQuantity(category.categoryId, variation.variationId, currentCartQuantity + 1);
            return;
        }

        setAddonQuantity(category.categoryId, variation.variationId, currentCartQuantity + minAdd);
    };

    const validateDecrement = (): boolean => hasValidVariation && currentCartQuantity > 0;

    const decrement = () => {
        if (!validateDecrement()) return;

        let newQuantity;
        if (hasInsufficientInventory) {
            // If not enough inventory, always decrease one by one
            newQuantity = currentCartQuantity - 1;
        } else {
            // Normal case: jump to 0 if going below minimum
            newQuantity = currentCartQuantity <= minAddonQuantity ? 0 : currentCartQuantity - 1;
        }

        setAddonQuantity(category.categoryId, variation.variationId, newQuantity);
    };

    // Button states are derived from the same validation logic
    const buttonStates = {
        isDisabledPlus: !validateIncrement(minAdd),
        isDisabledMinus: !validateDecrement(),
    };

    return {
        increment,
        decrement,
        ...buttonStates,
    };
}

function getPriceDetails(variations: AddonVariationFragment[]): PriceRange {
    if (variations?.length === 1) {
        const basePrice = variations[0].unitPrice?.find((u) => u.id === FeeID.BASE_PRICE);
        if (!basePrice) {
            return { lowestPrice: '', highestPrice: '' };
        }
        const priceString =
            basePrice.charge.amount === 0
                ? 'Free'
                : formatPrice(basePrice.charge.amount, basePrice.charge.currency);
        return { lowestPrice: priceString, highestPrice: priceString };
    }

    let lowestPrice: number | null = null;
    let highestPrice: number | null = null;
    let currency = 'CAD';

    variations?.forEach((variation) => {
        variation.unitPrice?.forEach((u) => {
            if (u.id !== FeeID.BASE_PRICE) {
                return;
            }
            if (lowestPrice === null || u.charge.amount < lowestPrice) {
                lowestPrice = u.charge.amount;
            }
            if (highestPrice === null || u.charge.amount > highestPrice) {
                highestPrice = u.charge.amount;
            }
            currency = u.charge.currency;
        });
    });

    const lowestPriceString = lowestPrice === null ? 'Free' : formatPrice(lowestPrice, currency);
    const highestPriceString = highestPrice === null ? 'Free' : formatPrice(highestPrice, currency);
    return { lowestPrice: lowestPriceString, highestPrice: highestPriceString };
}

function calculatePriceDifference(basePrice: number, variationPrice: number): string {
    if (variationPrice === 0) {
        return '';
    }

    if (variationPrice > basePrice) {
        return `+ ${formatPrice(variationPrice - basePrice, 'CAD')}`;
    }

    return '';
}

export {
    useAddonsCount,
    useTicketsCount,
    createQuantityHandlers,
    getPriceDetails,
    calculatePriceDifference,
};
