import { Box, Typography } from '@mui/joy';
import { IncreaseDecreaseContainer } from '../orderSummaryPageJoy.styled';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useCallback } from 'react';

export const QuantityControl = ({
    increment,
    decrement,
    quantity = 0,
    variationId,
    disabled = {
        minus: false,
        plus: false,
    },
    setColor = '#4A7754',
}: {
    increment: () => void;
    decrement: () => void;
    quantity?: number;
    variationId: string;
    disabled: {
        minus: boolean;
        plus: boolean;
    };
    setColor: string;
}) => {
    const handleIncrement = useCallback(() => {
        increment();
    }, [increment]);

    const handleDecrement = useCallback(() => {
        decrement();
    }, [decrement]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-start',
                marginBottom: '8px',
                gap: 2,
            }}
        >
            <IncreaseDecreaseContainer
                id={`remove_variation_${variationId}`}
                onClick={handleDecrement}
                disabled={disabled.minus}
                setcolor={setColor}
            >
                <RemoveIcon className="minus_icon" />
            </IncreaseDecreaseContainer>
            <Typography level="body-md" sx={{ fontWeight: 600, width: 20, textAlign: 'center' }}>
                {quantity}
            </Typography>
            <IncreaseDecreaseContainer
                id={`add_variation_${variationId}`}
                onClick={handleIncrement}
                disabled={disabled.plus}
                setcolor={setColor}
            >
                <AddIcon className="plus_icon" />
            </IncreaseDecreaseContainer>
        </Box>
    );
};
