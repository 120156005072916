import { A1CheckoutCartObject } from '../../types/common';
import { OrderSummaryEventQuery } from '../../graphql/graphql';
import { BaseAddonCacheReturn, useBaseAddonCache } from './useBaseAddonCache';
import { useAddonsCount, useTicketsCount } from './addon-helpers';

export function useAddonCardCalculations(
    event: OrderSummaryEventQuery['event'],
    cart: A1CheckoutCartObject,
    setAddonQuantity: (categoryId: string, variationId: string, quantity: number) => void,
    isUnlocked: (categoryId: string) => readonly [boolean, readonly string[]],
    pairIsUnlocked: (categoryId: string) => boolean
) {
    const addonsCount = useAddonsCount(cart);
    const ticketsCount = useTicketsCount(cart);
    const baseCache = useBaseAddonCache(
        event,
        isUnlocked,
        pairIsUnlocked,
        cart,
        addonsCount,
        ticketsCount,
        setAddonQuantity
    );

    function transformAddonCacheToArray(test: BaseAddonCacheReturn) {
        return Array.from(test, ([key, value]) => ({
            id: key,
            ...value,
        }));
    }

    const addonsArray = transformAddonCacheToArray(baseCache);

    // Sort base on unlockWith and order
    const sortAddonCategories = addonsArray.sort((a, b) => {
        const hasUnlockWithA = Boolean(a.category.unlockWith && Object.keys(a.category.unlockWith).length > 0);
        const hasUnlockWithB = Boolean(b.category.unlockWith && Object.keys(b.category.unlockWith).length > 0);
        
        // If one has unlockWith and the other doesn't, sort accordingly
        if (hasUnlockWithA !== hasUnlockWithB) {
            return hasUnlockWithA ? 1 : -1;
        }
        
        return a.category.order - b.category.order;
    });

    return sortAddonCategories;
}

export function addonIdsToKey(categoryIg: string, variationId: string) {
    return `${categoryIg}__${variationId}`;
}

export function addonKeyToIds(key: string) {
    return key.split('__');
}
