import { Modal, ModalDialog, Box, ModalClose } from '@mui/joy';

interface ImageModalJoyProps {
    imageKey: string;
    name: string;
    imageState: boolean;
    closeModal: () => void;
}

export default function ImageModalJoy({
    imageKey,
    name,
    imageState,
    closeModal,
}: ImageModalJoyProps) {
    return (
        <Modal
            onClose={() => closeModal()}
            open={imageState}
            sx={{
                border: 'none',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box sx={{ border: 'none', display: 'flex', flexDirection: 'column' }}>
                <ModalDialog sx={{ backgroundColor: 'transparent', border: 'none', padding: 0 }}>
                    <ModalClose sx={{ backgroundColor: 'white' }} />
                    <img
                        src={`https://media.admitone.com/addons/${imageKey}?width=350&height=350&fit=fill&fill=blur`}
                        alt={name}
                        style={{
                            borderRadius: 8,
                        }}
                    />
                </ModalDialog>
            </Box>
        </Modal>
    );
}
