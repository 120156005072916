/*
Implementation Details
Uses useMemo for performance optimization
Dependencies: [event?.id, isUnlocked]
Skips categories that are:
    Hidden (category.isHidden === true)
    Locked (determined by isUnlocked function)
    Missing variations

Skips variations that are missing a variationId
Calculates base price from unit price array, defaulting to 0 if not found
Handles missing or undefined data

Processing Flow
    Creates an empty Map
    Checks for valid event and addon categories
    Iterates through each category:

Validates category data
Checks if category is unlocked
Processes each variation within the category

For each variation:
    Creates a unique key
    Extracts base price
    Stores complete addon information in the cache
*/

import { useMemo } from 'react';
import {
    AddonCategoryFragment,
    AddonVariationObject,
    OrderSummaryEventQuery,
} from '../../graphql/graphql';
import { addonIdsToKey } from '.';
import { FeeID } from '../calculations';
import {
    ButtonStates,
    calculatePriceDifference,
    createQuantityHandlers,
    getPriceDetails,
    PriceRange,
} from './addon-helpers';
import { A1CheckoutCartObject } from '../../types/common';

type EnhancedAddonCategory = Omit<AddonCategoryFragment, 'variations'> & {
    variations: EnhancedVariation[];
  }

export interface BasicAddonInfo {
    category: EnhancedAddonCategory;
    hasSingleVariation: boolean;
    isAddonSoldOut: boolean;
    totalInventoryCount: number;
    totalAddonsCount: number;
    totalTicketsCount: number;
}

interface EnhancedVariation extends AddonVariationObject {
    priceRange: PriceRange;
    priceDifference: string;
    inventory: number;
    handlers: {
        increment: () => void;
        decrement: () => void;
    };
    buttonStates: ButtonStates;
    currentCartQuantity: number;
}

export type BaseAddonCacheReturn = Map<string, BasicAddonInfo>;

function useBaseAddonCache(
    event: OrderSummaryEventQuery['event'],
    isUnlocked: (categoryId: string) => readonly [boolean, readonly string[]],
    pairIsUnlocked: (categoryId: string) => boolean,
    cart: A1CheckoutCartObject,
    addonsCount: number,
    ticketsCount: number,
    setAddonQuantity: (categoryId: string, variationId: string, quantity: number) => void
): BaseAddonCacheReturn {
    return useMemo(() => {
        const cache = new Map<string, BasicAddonInfo>();

        if (!event?.addonCategories) {
            return cache;
        }

        for (const category of event.addonCategories) {
            if (!category?.variations || category.isHidden) continue;

            const hasUnlockRestrictions =
                category?.hashedUnlockCode && category.hashedUnlockCode.length > 0;
            const hasPairRestrictions =
                category.unlockWith && Object.keys(category.unlockWith).length > 0;

            const [unlocked] = isUnlocked(category.categoryId);
            let shouldShowAddon = false;

            if (!hasUnlockRestrictions && !hasPairRestrictions) {
                shouldShowAddon = true;
            } else if (hasUnlockRestrictions && unlocked) {
                shouldShowAddon = true;
            } else if (hasPairRestrictions && pairIsUnlocked(category.categoryId)) {
                shouldShowAddon = true;
            }

            if (!shouldShowAddon) continue;

            const enhancedVariations: EnhancedVariation[] = [];
            let totalInventoryCount = 0;

            for (const variation of category.variations) {
                if (!variation?.variationId) continue;

                const key = addonIdsToKey(category.categoryId, variation.variationId);
                const currentCartQuantity = cart.addons[key] || 0;

                const inventory =
                    variation.quantityLimit - variation.quantityDistributed.distributed;
                totalInventoryCount += inventory;

                // Create handlers
                const { increment, decrement, isDisabledPlus, isDisabledMinus } =
                    createQuantityHandlers({
                        variation: {
                            variationId: variation.variationId,
                            quantityLimit: variation.quantityLimit,
                        },
                        category,
                        inventory: inventory,
                        currentCartQuantity,
                        totalAddonsCount: addonsCount,
                        totalTicketsCount: ticketsCount,
                        setAddonQuantity,
                    });

                // Calculate price range and difference
                const basePrice =
                    variation.unitPrice?.find((u) => u.id === FeeID.BASE_PRICE)?.charge?.amount ??
                    0;
                const priceRange = getPriceDetails([variation]);
                const categoryBasePrice = Math.min(
                    ...category!
                        .variations!.map(
                            (v) =>
                                v.unitPrice?.find((u) => u.id === FeeID.BASE_PRICE)?.charge
                                    ?.amount ?? Infinity
                        )
                        .filter((price) => price !== Infinity)
                );
                const priceDifference = calculatePriceDifference(categoryBasePrice, basePrice);

                enhancedVariations.push({
                    ...variation,
                    priceRange,
                    priceDifference,
                    inventory,
                    handlers: { increment, decrement },
                    buttonStates: { isDisabledPlus, isDisabledMinus },
                    currentCartQuantity,
                });
            }

            cache.set(category.categoryId, {
                category: {
                    ...category,
                    variations: enhancedVariations,
                },
                hasSingleVariation: (category.variations?.length || 0) === 1,
                isAddonSoldOut: totalInventoryCount <= 0,
                totalInventoryCount,
                totalAddonsCount: addonsCount,
                totalTicketsCount: ticketsCount,
            });
        }

        return cache;
    }, [
        event?.addonCategories,
        isUnlocked,
        pairIsUnlocked,
        cart.addons,
        addonsCount,
        ticketsCount,
        setAddonQuantity,
    ]);
}

export { useBaseAddonCache };
