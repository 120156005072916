import { addonKeyToIds } from './addon/index.ts';
import {
    AddonVariationUnitPrice,
    ConfirmationPageEventQuery,
    Maybe,
    OrderSummaryEventQuery,
    PaymentPageEventQuery,
    TicketCategoryUnitPrice,
} from '../graphql/graphql.ts';
import { A1CheckoutCartObject } from '../types/common.ts';

export enum LineItemType {
    TICKET_LINE_ITEM,
    ADDON_LINE_ITEM,
}

export enum FeeID {
    BASE_PRICE = '000',
}

export interface FeeItem {
    id: string;
    name: string;
    subTotal: number;
    order?: number;
}

export interface LineItem extends FeeItem {
    type: LineItemType;
    quantity: number;
    unitPrice: Array<Maybe<AddonVariationUnitPrice> | Maybe<TicketCategoryUnitPrice>>;
}

export function convertCartToLineItems(
    cart: A1CheckoutCartObject,
    event:
        | OrderSummaryEventQuery['event']
        | PaymentPageEventQuery['event']
        | ConfirmationPageEventQuery['event']
) {
    const items: LineItem[] = [];

    for (const [categoryId, qty] of Object.entries(cart.quantities)) {
        if (qty <= 0) {
            continue;
        }

        const category = event?.ticketCategories?.find((cat) => cat?.categoryId === categoryId);

        if (!category) {
            continue;
        }

        const basePrice = category?.unitPrice?.find((u) => u?.id === FeeID.BASE_PRICE);

        items.push({
            type: LineItemType.TICKET_LINE_ITEM,
            id: categoryId,
            name: category!.name!,
            quantity: qty,
            unitPrice: category!.unitPrice!,
            subTotal: (basePrice?.charge?.amount ?? 0) * qty,
        });
    }

    for (const [key, qty] of Object.entries(cart.addons)) {
        if (qty <= 0) {
            continue;
        }

        const [categoryId, variationId] = addonKeyToIds(key);

        const category = event?.addonCategories?.find((cat) => cat?.categoryId === categoryId);

        if (!category) {
            continue;
        }

        const variation = category?.variations?.find(
            (variation) => variation.variationId === variationId
        );

        if (!variation) {
            continue;
        }

        const isSingleVariation = category?.variations?.length === 1;
        const basePrice = variation?.unitPrice?.find((u) => u?.id === FeeID.BASE_PRICE);

        items.push({
            type: LineItemType.ADDON_LINE_ITEM,
            id: key,
            name: isSingleVariation ? category!.name : `${category.name} / ${variation.name}`,
            quantity: isNaN(qty) ? 0 : qty,
            unitPrice: variation!.unitPrice!,
            subTotal: isNaN(qty) ? 0 : (basePrice?.charge?.amount ?? 0) * qty,
        });
    }

    return items;
}

export function extractFeeItemsFromLineItems(lineItems: LineItem[]) {
    const fees: FeeItem[] = [];

    if (!Array.isArray(lineItems)) {
        return fees;
    }

    for (const lineItem of lineItems) {
        if (!Array.isArray(lineItem?.unitPrice)) {
            continue;
        }

        for (const unitPrice of lineItem.unitPrice) {
            if (!unitPrice?.id || unitPrice?.id === FeeID.BASE_PRICE) {
                continue;
            }

            const subTotal = (unitPrice?.charge?.amount ?? 0) * lineItem.quantity;
            const fee = fees.find((f) => f.id === unitPrice.id);
            const order = unitPrice.order;

            if (fee) {
                fee.subTotal += subTotal;
            } else {
                fees.push({
                    id: unitPrice.id,
                    name: unitPrice.name!,
                    subTotal,
                    order: order ?? 0,
                });
            }
        }
    }

    return fees;
}

export function calculateTotal(lineItems: LineItem[], feeItems: FeeItem[]) {
    return [...lineItems, ...feeItems].reduce((total, item) => total + item.subTotal, 0);
}

export function removeZeroQuantities(quantities: Record<string, number>) {
    return Object.fromEntries(Object.entries(quantities).filter(([_, qty]) => qty > 0));
}
